@font-face {
    font-family: "Montserrat";
    src: local("MontserratRegular"),
      url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Montserrat";
    src: local("MontserratMedium"),
      url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Montserrat";
    src: local("MontserratSemiBold"),
        url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
}
